@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&family=Poppins&display=swap");

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: "Poppins", sans-serif;
   color: var(--black);
}

:root {
   --priCol: #9900fa;
   --black: #2e2e2e;
   --grey: #505050;
}

a {
   text-decoration: none;
}

ul,
ol {
   list-style-type: none;
}
